import cn from "classnames";
import "./they_clap_us.sass";
import clapQuot from "../../img/clap_quot.svg";
import React from "react";
import RowItem from "../layouts/RowItem";
import {fluidSizing, fluidTypography, media} from "../styles/MediaMixins";
import styled from "styled-components";

const Name = styled.div`
    font-size: ${25.2 / 16}rem;
    line-height: ${42 / 16}rem;
    ${fluidTypography(16, 25, 480, 1408)}
    ${fluidSizing('line-height', 19, 42, 480, 1408)}
    color: #11223F;
    font-weight: bold;
    text-transform: uppercase;
    ${media.touch`margin-top: ${85 / 16}rem`};
    ${media.desktop`margin-top: ${55 / 16}rem`};
    ${media.touch`margin-bottom: ${5 / 16}rem`};
`

const Position = styled.div`
    font-size: ${19 / 16}rem;
    line-height: ${42 / 16}rem;
    ${fluidTypography(14, 19, 480, 1408)}
    ${fluidSizing('line-height', 16, 42, 480, 1408)}
    color: #11998ECC;
    font-weight: bold;
    ${media.touch`margin-bottom: ${10 / 16}rem`}
`

const Comment = styled.div`
    font-size: ${18 / 16}rem;
    line-height: ${32 / 16}rem;
    ${fluidTypography(12, 18, 480, 1408)}
    ${fluidSizing('line-height', 16, 32, 480, 1408)}
    color: #33333366;
    font-weight: 500;
    margin-bottom: 16px;
    text-align: left;
`

export default ({name, tile, comment}) => {
    return (
        <RowItem className={cn('has-padding-bottom-5 has-padding-top-5 has-padding-left-15 has-padding-right-15')}>
            <div className={cn('testimonial-wrapper', 'is-paddingless')}>
                <div className={cn('testimonial-wrapper2', 'is-paddingless')}>
                    <div className={cn('testimonial', 'is-paddingless has-margin-left-40-desktop has-margin-left-20-touch has-margin-top-10 has-margin-bottom-10')}>
                        <Name>{name}</Name>
                        <Position>{tile}</Position>
                        <Comment>{comment}</Comment>
                        <div className={cn('test-quote')}>
                            {!!name && <img alt={'Green quote sign'} src={clapQuot}/>}
                        </div>
                    </div>
                </div>
            </div>
        </RowItem>
    )
};