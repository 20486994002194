import React from "react";
import cn from 'classnames'

export default ({onClick, active}) => {
    return (
        <li className={'has-margin-top-50 is-borderless'}>
            <button
                className={cn('they-clap-us-dot is-borderless has-margin-left-15', {'active-dot': active})}
                onClick={() => onClick()}
            />
        </li>
    );
};
