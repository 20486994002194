import styled from "styled-components";
import {fluidSizing, fluidTypography} from "../styles/MediaMixins";

const Paragraph = styled.div`
    ${fluidTypography(14, 18)};
    ${fluidSizing('line-height',23, 32)};
    font-weight: 500;
    letter-spacing: 0;
    color: #33333366;
`

export default Paragraph;