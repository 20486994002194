import React from "react";
import {useStaticQuery, graphql} from 'gatsby'
import Carousel from 'react-multi-carousel';
import Img from 'gatsby-image';
import './carousel.sass';
import Title from "../title/Title";
import {media} from "../styles/MediaMixins";
import styled from "styled-components";
import Row from "../layouts/Row";
import RowItem from "../layouts/RowItem";

const Arrow = styled.button`
  background-color: transparent;
  position: absolute;
  height: 100%;
  width: 69px;
  max-width: 69px;
  cursor: pointer;
  border: none;
`

const ArrowLeft = styled(Arrow)`
  left: 0;
`

const ArrowRight = styled(Arrow)`
  right: 0;
`

const LocalTitle = styled(Title)`
  text-align: center;
  margin-bottom: ${25 / 16}rem;
  ${media.widescreen`margin-bottom: ${43 / 16}rem`};
`

const RightArrow = ({onClick}) => {
  return <ArrowRight aria-label={"Go to next slide"} onClick={() => onClick()}>
    <img src={'/img/icons/clap_us/clap_us_right.png'} alt={'Next Slide Arrow'}/>
  </ArrowRight>;
};
const LeftArrow = ({onClick}) => {
  return <ArrowLeft aria-label={"Go to previous slide"} onClick={() => onClick()}>
    <img src={'/img/icons/clap_us/clap_us_right.png'} className={'rotateimg180'} alt={'Previous Slide Arrow'}/>
  </ArrowLeft>;
};

export default function WorkWithUsSlider() {
  const {allFile} = useStaticQuery(
    graphql`
        query WorkWithUsSlider {
            allFile(filter: {relativeDirectory: {eq: "work_with_us"}}) {
                nodes {
                    childImageSharp {
                        fixed(width: 160) {
                            ...GatsbyImageSharpFixed_withWebp_tracedSVG
                        }
                    }
                }
            }
        }
    `);
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: {max: 4000, min: 3000},
      items: 5,
    },
    desktop: {
      breakpoint: {max: 3000, min: 1216},
      items: 4,
    },
    size3: {
      breakpoint: {max: 1216, min: 924},
      items: 3,
    },
    size2: {
      breakpoint: {max: 924, min: 610},
      items: 2
    },
    size1: {
      breakpoint: {max: 610, min: 0},
      items: 1
    },
  };

  return (
    <>
      <div className={'gen_container'}>
        <Row>
          <RowItem>
            <LocalTitle>
              Who Love to Work with Cypress
            </LocalTitle>
          </RowItem>
        </Row>
      </div>
      <Carousel
        customLeftArrow={<LeftArrow/>}
        customRightArrow={<RightArrow/>}
        containerClass={'work-with-us-container work-with-us-background'}
        itemClass={'has-margin-auto has-text-centered'}
        swipeable={true}
        responsive={responsive}
        infinite={true}
        ssr={false}
      >
        {allFile.nodes.map((node, index) => (
          <Img alt={'They work with us'} key={index} className={'has-margin-auto'} fixed={node.childImageSharp.fixed}/>
        ))}
      </Carousel>
    </>
  )
}