import React from "react";
import './carousel.sass'
import Testimonial from "./Testimonial";
import Carousel from "react-multi-carousel";
import CustomDot from "./CustomDot";
import Title from "../title/Title";
import Container from "../layouts/Container";
import Row from "../layouts/Row";
import styled from "styled-components";
import Paragraph from "../paragraph/Paragraph";
import {media} from "../styles/MediaMixins";
import RowItem from "../layouts/RowItem";

const TestimonialRow = styled(Row)`
  width: 86vw;
  padding-left: unset !important;
  padding-right: unset !important;
`

const LocalTitle = styled(Title)`
  margin-bottom: ${11 / 16}rem
  ${media.widescreen`margin-bottom: 1rem`};
`

const LocalParagraph = styled(Paragraph)`
  margin-top: ${11 / 16}rem;
  margin-bottom: ${20 / 16}rem;
  ${media.widescreen`
    margin-top: 1rem;
    margin-bottom: 1rem;
  `};
`

const EmptyRowItem = styled(RowItem)`
    min-height: ${283 / 16}rem;
    max-height: ${283 / 16}rem;
    height: ${283 / 16}rem;
`

export default () => {
  const testimonials_small = [];
  const testimonials_large = [];

  const testimonial1 =
    <Testimonial
      name={"John Carter"}
      tile={"Design Director at CSC Capital"}
      comment={"“Fantastic work, great experience! I hope to work with Cypress again soon.”"}/>
  const testimonial2 =
    <Testimonial
      name={"NAREK SAFARYAN"}
      tile={"CEO at Renderforest"}
      comment={"“This is the most competent service they provided in addition to our platform. They are true champions of inspiration.”"}/>
  const testimonial3 =
    <Testimonial
      name={"DIRK EGGERMONT"}
      tile={"Business Partner at Casier Recycling"}
      comment={"“For me, working with a team of reliable, professional people is a major factor. I am really glad I found Cypress, a great team of professionals, who outdid my expectations.”"}/>
  const testimonial4 =
    <Testimonial
      name={"ARMEN DARABYAN"}
      tile={"CEO at Cretrix"}
      comment={"“Cypress's flexibility was very important to me. Cypress is one of our best partners and I highly recommend their services.”"}/>
  const testimonial5 =
    <Testimonial
      name={"RUZANNA NAHAPETYAN"}
      tile={"CEO at SimplyPay Malta"}
      comment={"“The team followed the agreed timeline and budget flawlessly despite the time-zone challenges and strict initial requirements.”"}/>
  const emptyTestimonial = <EmptyRowItem/>
  testimonials_small.push(
    <TestimonialRow key={"1a"}>
      {testimonial1}
    </TestimonialRow>,
    <TestimonialRow key={"2a"}>
      {testimonial2}
    </TestimonialRow>,
    <TestimonialRow key={"3a"}>
      {testimonial3}
    </TestimonialRow>,
    <TestimonialRow key={"4a"}>
      {testimonial4}
    </TestimonialRow>,
    <TestimonialRow key={"5a"}>
      {testimonial5}
    </TestimonialRow>,
  );
  testimonials_large.push(
    <Container key={1}>
      <TestimonialRow maxWidth={1600}>
        {testimonial1}
        {testimonial2}
      </TestimonialRow>
      <TestimonialRow maxWidth={1600}>
        {testimonial3}
        {testimonial4}
      </TestimonialRow>
    </Container>
  );
  testimonials_large.push(
    <Container key={2}>
      <TestimonialRow maxWidth={1600}>
        {testimonial5}
        {emptyTestimonial}
      </TestimonialRow>
      <TestimonialRow maxWidth={1600}>
        {emptyTestimonial}
        {emptyTestimonial}
      </TestimonialRow>
    </Container>
  );


  const responsive = {
    size1: {
      breakpoint: {max: 10000, min: 0},
      items: 1
    },
  };
  const RightArrow = ({onClick}) => {
    return <button aria-label={"Go to next slide"}
                   className={'button is-borderless right they-clap-us-arrow they-clap-us-arrow-background'}
                   onClick={() => onClick()}>
      <img src={'/img/icons/clap_us/clap_us_right.png'} alt={'Next Slide Arrow'}/>
    </button>;
  };
  const LeftArrow = ({onClick}) => {
    return <button aria-label={"Go to previous slide"}
                   className={'button is-borderless left they-clap-us-arrow they-clap-us-arrow-background'}
                   onClick={() => onClick()}>
      <img src={'/img/icons/clap_us/clap_us_right.png'} className={'rotateimg180'} alt={'Previous Slide Arrow'}/>
    </button>;
  };

  return (
    <>
      <Container>
        <Row>
          <LocalTitle>They Clap Us</LocalTitle>
        </Row>
        <Row>
          <LocalParagraph>
            Our applications are designed with a simple philosophy in
            mind - we believe that information, in the right hands, has the power to change. We
            specialize in bespoke software development that puts the user first and delivers
            life-enhancing digital experiences.
          </LocalParagraph>
        </Row>
      </Container>
      <Carousel
        className={'is-hidden-touch is-hidden-desktop-only'}
        showDots={true}
        customDot={<CustomDot/>}
        customLeftArrow={<LeftArrow/>}
        customRightArrow={<RightArrow/>}
        containerClass={'they-clap-us-container they-clap-us-background'}
        itemClass={'has-margin-auto custom-slide'}
        swipeable={true}
        responsive={responsive}
        infinite={true}
        ssr={false}
      >
        {testimonials_large}
      </Carousel>
      <Carousel
        className={'is-hidden-widescreen'}
        showDots={true}
        customDot={<CustomDot/>}
        arrows={false}
        containerClass={'they-clap-us-container they-clap-us-background'}
        itemClass={'has-margin-auto custom-slide'}
        swipeable={true}
        responsive={responsive}
        infinite={true}
        ssr={false}
      >
        {testimonials_small}
      </Carousel>
    </>
  );
}